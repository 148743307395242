import { createSlice } from '@reduxjs/toolkit';
import { ENTER_FARE_QUOTE } from 'views/CreateTrip/constants/animationConstants';
import { adminSelectionType } from 'views/CreateTrip/constants/tripConstants';

const initialState = {
	/* leg on which the user is currently on - 0, 1, 2..  */
	selectedLeg: null,
	/* type of the selected leg - flight, hotel */
	selectedLegType: '',
	/* status of different legs: idle, selected */
	legStatus: [],
	/* modes for all legs - flight | hotel  */
	legModes: [],
	/* total count of the legs */
	legCount: null,
	/* ids of all the legs */
	legIDs: [],
	legUniqueIDs: [],
	/* trip id */
	tripID: null,
	/* total traveler count */
	totalTravelerCount: 0,
	/* is initial setup complete - after storing all the leg related info*/
	initialSetupComplete: false,
	/* options selected by the user */
	userSelections: [],
	/* stream status of different legs */
	streamStatus: [],
	/* price of the option selected by the user for different legs*/
	userSelectionPriceArray: [],
	/* filters selected by the user */
	userFilters: {},
	selectedFilters: {},
	sliderFilters: {},
	/* filters in api */
	current_filters: {},
	current_sort: '',
	hotelViewMode: 'hotel',
	hotelID: null,
	mapView: false,
	sortValue: {},
	hotelSearchText: {},
	isDefaultFilter: {},
	streamComplete: {},
	/* selection id returned from backend when selecting an option, 
	needs to be sent in subsequent selection requests */
	selectionID: undefined,
	currentHotelData: null,

	// will be removed once L2 filter is here
	// this hotel data will not change ever, this will used to refer when original sorting order is asked for.
	hotelDataForReference: null,
	/* airport_details - city name, full name and other airport related info */
	airportDetails: {},
	/* more information about a fare plan for a flight */
	brandDescription: [],
	showImageGallery: false,
	showRoomImageGallery: false,
	/* consists of information for filtering round trip flights */
	checkboxState: [],
	/* rt package request id needed for return US-US domestic flights */
	rtPackageRequestId: '',
	/*trip details */
	tripDetails: [],
	errorType: {},
	/* leg id to index mapping */
	legUniqueIDToIndexMapping: {},
	/* hide proceed btn if booking done */
	isViewMode: false,
	adminViewMode: null,
	triggerProceed: false,
	showProceedBtn: false,
	/* to move to next slide after a flight/hotel is selected */
	moveToNextSlide: false,
	tripTimer: false,
	modeType: {},
	showAnimation: true,
	isMobileAppView: false,
	creditPopup: null,
	resetDiscFare: false,
	rescheduleModificationID: null,
	loaderAnimations: {
		currentStep: ENTER_FARE_QUOTE,
		presentState: 'enter',
	},
	moreDetailsClicked: false,
	cancellationPopupClicked: false,
	multicity: false,
	hotelIndexes: [],
	flightIndexes: [],
};

export const userTripSelections = createSlice({
	name: 'userTripSelections',
	initialState,
	reducers: {
		setCurrentFilter: (state, action) => {
			state.current_filters = action.payload;
		},
		setSliderFilters: (state, action) => {
			state.sliderFilters = action.payload;
		},
		setCurrentSort: (state, action) => {
			state.current_sort = action.payload;
		},
		setMoreDetailsClicked: (state, action) => {
			state.moreDetailsClicked = action.payload;
		},
		setCancellationPopupClicked: (state, action) => {
			state.cancellationPopupClicked = action.payload;
		},
		setTripDetails: (state, action) => {
			state.tripDetails = action.payload;
		},
		setTripLegType: (state, action) => {
			state.selectedLegType = action.payload;
		},
		setTripLegActive: (state, action) => {
			state.selectedLeg = action.payload;
			state.selectedLegType = state.legModes[action.payload];
		},
		setTripAllLegSelectionStatus: (state, action) => {
			state.legStatus = action.payload;
		},
		updateTripLegSelectionStatus: (state, action) => {
			state.legStatus[action.payload.id] = action.payload.value;
		},
		setUserSelectionsInit: (state, action) => {
			state.userSelections = action.payload;
		},
		setTripLegCount: (state, action) => {
			state.legCount = action.payload;
		},
		setTripLegIDs: (state, action) => {
			state.legIDs = action.payload;
		},
		setTripLegUniqueIDs: (state, action) => {
			state.legUniqueIDs = action.payload;
		},
		setTripLegModes: (state, action) => {
			state.legModes = action.payload;
		},
		setTripIDRedux: (state, action) => {
			state.tripID = action.payload;
		},
		setTotalTravelerCount: (state, action) => {
			state.totalTravelerCount = action.payload;
		},
		setRTPackageRequestId: (state, action) => {
			state.rtPackageRequestId = action.payload;
		},
		setTripInitialSetupStatus: (state, action) => {
			state.initialSetupComplete = action.payload;
		},
		setUserSelections: (state, action) => {
			state.userSelections[action.payload.leg] = action.payload.result;
		},
		setTripStreamStatus: (state, action) => {
			state.streamStatus = action.payload;
		},
		setTripStreamSingleStatus: (state, action) => {
			state.streamStatus[action.payload.leg] = action.payload.status;
			// if (action.payload.status.streamType === streamEvent.END) {
			// 	state.streamComplete[state.legIDs[action.payload.leg]] = true;
			// }
		},
		setUserSelectionPrice: (state, action) => {
			state.userSelectionPriceArray[action.payload.leg] =
				action.payload.price;
		},
		setSelectedFilters: (state, action) => {
			state.selectedFilters[action.payload.leg] = action.payload.filter;
			if (action.payload.isDefaultFilter)
				state.isDefaultFilter[action.payload.leg] = false;
			else state.isDefaultFilter[action.payload.leg] = true;
		},
		setAllSelectedFilters: (state, action) => {
			state.selectedFilters = action.payload.filter;
			if (action.payload.defaultFilter)
				state.isDefaultFilter = action.payload.defaultFilter;
		},
		setUserFilters: (state, action) => {
			state.userFilters[action.payload.leg] = action.payload.filter;
		},
		setHotelViewMode: (state, action) => {
			state.hotelViewMode = action.payload;
		},
		setHotelID: (state, action) => {
			state.hotelID = action.payload;
		},
		setMapView: (state, action) => {
			state.mapView = action.payload;
		},
		setSortValue: (state, action) => {
			state.sortValue[action.payload.leg] = action.payload.sort;
		},
		setSelectionID: (state, action) => {
			state.selectionID = action.payload;
		},
		setCurrentHotelData: (state, action) => {
			state.currentHotelData = action.payload;
		},
		// will be removed once L2 filter is here
		setHotelDataForReference: (state, action) => {
			state.hotelDataForReference = action.payload;
		},
		setAirportDetails: (state, action) => {
			state.airportDetails = {
				...state.airportDetails,
				...action.payload,
			};
		},
		setFlightBrandDescription: (state, action) => {
			state.brandDescription[state.selectedLeg] = action.payload;
		},
		setShowImageGallery: (state, action) => {
			state.showImageGallery = action.payload;
		},
		setShowRoomImageGallery: (state, action) => {
			state.showRoomImageGallery = action.payload;
		},
		setAllFlightCheckboxState: (state, action) => {
			state.checkboxState = action.payload;
		},
		setFlightCheckboxState: (state, action) => {
			let currentFilters = {
				...state.userFilters[state.legIDs[state.selectedLeg]],
			};
			state.checkboxState[action.payload.onward] = action.payload.obj;
			if (
				state.checkboxState[0]?.value &&
				state.checkboxState[1]?.value
			) {
				currentFilters = {
					...currentFilters,
					...{
						onward: 2,
						leg_hash: `${state.checkboxState[0].filterValue.leg_hash},${state.checkboxState[1].filterValue.leg_hash}`,
					},
				};
			} else if (
				state.checkboxState[0]?.value &&
				!state.checkboxState[1]?.value
			) {
				currentFilters = {
					...currentFilters,
					...state.checkboxState[0].filterValue,
				};
			} else if (
				state.checkboxState[1]?.value &&
				!state.checkboxState[0]?.value
			) {
				currentFilters = {
					...currentFilters,
					...state.checkboxState[1].filterValue,
				};
			} else if (
				!state.checkboxState[0]?.value &&
				!state.checkboxState[1]?.value
			) {
				delete currentFilters['onward'];
				delete currentFilters['leg_hash'];
			}
			userTripSelections.caseReducers.setSelectedFilters(state, {
				payload: {
					leg: state.legIDs[state.selectedLeg],
					filter: currentFilters,
				},
			});
		},
		setHotelSearchText: (state, action) => {
			state.hotelSearchText[action.payload.leg] = action.payload.search;
		},
		setDefaultFilter: (state, action) => {
			state.isDefaultFilter[action.payload.leg] =
				action.payload.defaultFilter;
		},
		setErrorType: (state, action) => {
			state.errorType[action.payload.leg] = action.payload.error;
		},
		setStreamComplete: (state, action) => {
			state.streamComplete[action.payload.leg] = action.payload.status;
		},
		setLegUniqueIDToIndexMapping: (state, action) => {
			state.legUniqueIDToIndexMapping = action.payload;
		},
		resetStreamStatus: (state) => {
			state.streamStatus = state.legIDs.map(() => {
				return { showPrice: false, streamType: 'none' };
			});
			state.initialSetupComplete = false;
			state.tripTimer = false;
		},
		setResultsViewMode: (state, action) => {
			state.isViewMode = action.payload;
		},
		setAdminViewMode: (state, action) => {
			const directChange = action.payload.direct;
			if (directChange) {
				state.adminViewMode = action.payload.viewMode;
			} else {
				const selection_status = action.payload.selection_status;
				const payment_status = action.payload.payment_status;
				let adminViewMode = null;
				// For admins we enable/disable btns/popups based on selection_status
				if (selection_status === 0) {
					adminViewMode = {
						showExpiry: true,
						showConfirmBtn: false,
						reselect_package: false,
						type: adminSelectionType.ALL,
					};
					if (payment_status === 3 || payment_status === 4) {
						adminViewMode = {
							showExpiry: false,
							showConfirmBtn: true,
							reselect_package: false,
						};
					}
				}
				if (
					selection_status === 2 ||
					selection_status === 3 ||
					selection_status === 4 ||
					selection_status === 5
				) {
					adminViewMode = {
						showExpiry: false,
						showConfirmBtn: true,
						reselect_package: false,
					};
				}
				if (selection_status === 6 || selection_status === 7) {
					adminViewMode = {
						showExpiry: false,
						showConfirmBtn: false,
						reselect_package: true,
						leg_information: action.payload.leg_information,
						old_selection_id: action.payload.old_selection_id,
					};
				}
				state.adminViewMode = adminViewMode;
				state.isViewMode = true;
			}
		},
		setTriggerProceed: (state, action) => {
			state.triggerProceed = action.payload;
		},
		setInitialSortValue: (state, action) => {
			state.sortValue = action.payload;
		},
		setShowProceedBtn: (state, action) => {
			state.showProceedBtn = action.payload;
		},
		setTripTimer: (state, action) => {
			state.tripTimer = action.payload;
		},
		setAnimation: (state, action) => {
			state.showAnimation = action.payload;
		},
		setModeType: (state, action) => {
			state.modeType = action.payload;
		},
		setIsMobileAppView: (state, action) => {
			state.isMobileAppView = action.payload;
		},
		setCreditPopup: (state, action) => {
			state.creditPopup = action.payload;
		},
		setResetDiscFare: (state, action) => {
			state.resetDiscFare = action.payload;
		},
		setResultsRescheduleModificationID: (state, action) => {
			state.rescheduleModificationID = action.payload;
		},
		setLoaderAnimationSteps: (state, action) => {
			state.loaderAnimations.currentStep = action.payload;
		},
		setLoaderAnimationCurrentState: (state, action) => {
			state.loaderAnimations.presentState = action.payload;
		},
		setMultiCity: (state, action) => {
			state.multicity = action.payload;
		},
		setHotelIndexes: (state, action) => {
			state.hotelIndexes = action.payload;
		},
		setFlightIndexes: (state, action) => {
			state.flightIndexes = action.payload;
		},
		resetUserSelections: (state, action) => {
			const { elements = [], mode = '' } = action.payload;
			if (mode && elements.length > 0) {
				elements.forEach((i) => {
					state.userSelections[i] = null;
					state.userSelectionPriceArray[i] = null;
					state.legStatus[i] = 'idle';
				});
			}
		},
	},
});

export const {
	setTripDetails,
	setTripLegActive,
	setTripAllLegSelectionStatus,
	updateTripLegSelectionStatus,
	setUserSelectionsInit,
	setTripLegCount,
	setTripLegIDs,
	setTripLegUniqueIDs,
	setTripLegModes,
	setTripIDRedux,
	setTotalTravelerCount,
	setRTPackageRequestId,
	setTripInitialSetupStatus,
	setTripLegResults,
	setUserSelections,
	setTripStreamStatus,
	setTripStreamSingleStatus,
	setUserSelectionPrice,
	setSelectedFilters,
	setHotelViewMode,
	setHotelID,
	setMapView,
	setSortValue,
	setSelectionID,
	setCurrentHotelData,
	setAirportDetails,
	setFlightBrandDescription,
	setShowImageGallery,
	setShowRoomImageGallery,
	setFlightCheckboxState,
	setUserFilters,
	setDefaultFilter,
	setErrorType,
	setStreamComplete,
	setLegUniqueIDToIndexMapping,
	setTripLegType,
	resetStreamStatus,
	setAllSelectedFilters,
	setAllFlightCheckboxState,
	setResultsViewMode,
	setTriggerProceed,
	setInitialSortValue,
	setShowProceedBtn,
	setTripTimer,
	setAdminViewMode,
	setModeType,
	setAnimation,
	setIsMobileAppView,
	setCreditPopup,
	setResetDiscFare,
	setResultsRescheduleModificationID,
	setLoaderAnimationSteps,
	setLoaderAnimationCurrentState,
	setHotelDataForReference,
	setMoreDetailsClicked,
	setCancellationPopupClicked,
	setMultiCity,
	resetUserSelections,
	setCurrentFilter,
	setCurrentSort,
	setSliderFilters,
	setHotelIndexes,
	setFlightIndexes,
} = userTripSelections.actions;

export default userTripSelections.reducer;
